const meta = {
  base64encoder: {
    title: "Base64 Encoder Online Tool",
    description:
      "Base64 Encoder Online Tool helps you encode a plain text string to base64 encoded string. If you have a plain text you can use this online tool to convert it to base64 encoded string.",
    link: "/base64-encoder",
  },
  base64decoder: {
    title: "Base64 Decoder Online Tool",
    description:
      "Base64 Decoder Online Tool helps you decode an encoded text to plain text. If you have a base64 encoded text you can use this online tool to convert it to plain text.",
    link: "/base64-decoder",
  },
  base32encoder: {
    title: "Base32 Encoder Online Tool",
    description:
      "Base32 Encoder Online Tool helps you encode a plain text string to base32 encoded string. If you have a plain text you can use this online tool to convert it to base32 encoded string.",
    link: "/base32-encoder",
  },
  base32decoder: {
    title: "Base32 Decoder Online Tool",
    description:
      "Base32 Decoder Online Tool helps you decode an encoded text to plain text. If you have a base32 encoded text you can use this online tool to convert it to plain text.",
    link: "/base32-decoder",
  },
  urlencoder: {
    title: "URL Encoder Online Tool",
    description:
      "URL Encoder Online Tool helps you to encode an URL with non conforming characters and generates corresponding hexadecimal digits.",
    link: "/url-encoder",
  },
  urldecoder: {
    title: "URL Decoder Online Tool",
    description:
      "URL Decoder Online Tool helps you to decode an encoded URL to plain text string. If you found a URL which contains values such as %55 etc. paste that URL here and convert it to plain text.",
    link: "/url-decoder",
  },
}
export default meta
