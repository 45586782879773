import React from "react"

function PanelTitle({ titleText }) {
  return (
    <div className="x_title">
      <h2>{titleText}</h2>
      <div className="clearfix"></div>
    </div>
  )
}

export default PanelTitle
