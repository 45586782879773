import React from "react"

function PageTitle({ titleText }) {
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>{titleText}</h3>
        </div>
      </div>
      <div className="clearfix"></div>
    </>
  )
}

export default PageTitle
