import React from "react"

function Panel({ children }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="x_panel">{children}</div>
      </div>
    </div>
  )
}

export default Panel
