import React, { useState } from "react"
import Layout from "../layout"
import PageTitle from "../components/page-title"
import Panel from "../components/panel"
import PanelContent from "../components/panel/content"
import PanelTitle from "../components/panel/title"
import Seo from "../components/seo"
import meta from "../meta-constants"

function Base64Decoder() {
  const { title, description } = meta.base64decoder
  const [formData, setFormData] = useState({
    encoded: "",
    decoded: "",
  })

  const onChange = e => {
    setFormData(formData => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const decode = e => {
    e && e.preventDefault()
    let decodedStr = ""
    try {
      decodedStr = atob(formData.encoded)
    } catch (err) {
      decodedStr =
        "Failed to decode: The string to be decoded is not correctly encoded."
    }
    setFormData(formData => ({
      ...formData,
      decoded: decodedStr,
    }))
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <PageTitle titleText={title} />
      <Panel>
        <PanelTitle titleText="Convert Base 64 Encoded Text to Plain Text" />
        <PanelContent>
          <form id="form">
            <label htmlFor="encoded">
              Enter encoded text below and click Decode button :
            </label>
            <textarea
              id="encoded"
              required="required"
              className="form-control"
              name="encoded"
              style={{ height: "150px" }}
              onChange={onChange}
              value={formData.encoded}
            ></textarea>

            <br />
            <span
              tabIndex="0"
              role="button"
              className="btn btn-primary"
              onClick={decode}
              onKeyDown={decode}
            >
              Decode
            </span>

            <br />
            <br />

            <label htmlFor="decoded">
              Converted text will be shown in below text box :
            </label>
            <textarea
              id="decoded"
              required="required"
              className="form-control"
              name="decoded"
              style={{ height: "150px" }}
              onChange={onChange}
              value={formData.decoded}
            ></textarea>
          </form>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"About " + title} />
        <PanelContent>
          <p>{description}</p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="What is Base64?" />
        <PanelContent>
          <p>
            In programming, Base64 is a group of binary-to-text encoding schemes
            that represent binary data (more specifically, a sequence of 8-bit
            bytes) in an ASCII string format by translating the data into a
            radix-64 representation. The term Base64 originates from a specific
            MIME content transfer encoding. Each non-final Base64 digit
            represents exactly 6 bits of data. Three 8-bit bytes (i.e., a total
            of 24 bits) can therefore be represented by four 6-bit Base64
            digits.
          </p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="How to use Base64 Decoder Online Tool" />
        <PanelContent>
          <p>Use below steps to decode a base64 encoded string:</p>
          <ol>
            <li>Open Base64 Decoder Online Tool</li>
            <li>Enter Base64 encoded text in first text box</li>
            <li>Click on Decode button</li>
            <li>Check the second text box for decoded plain text output</li>
          </ol>
        </PanelContent>
      </Panel>
    </Layout>
  )
}

export default Base64Decoder
